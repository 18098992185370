.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(90deg, rgba(19, 182, 184, 1) 0%, rgba(73, 208, 190, 1) 100%);
  padding-left: 200px;
  padding-right: 200px;
  height: 100vh;

    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

      @media (min-width: 320px) and (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
        padding-top: 96px;
        padding-bottom: 180px;
      }

             
      /* Media Query for low resolution  Tablets, Ipads */
      @media (min-width: 481px) and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
        padding-top: 96px;
        padding-bottom: 180px;
      }

  .column {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        width: 29em;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        width: 29em;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 29em;
      }


      /* Media Query for Mobile Devices */
      @media (max-width: 480px) {
        align-items: center;
        text-align: center;
        margin-right: 0;
        margin-bottom: 30px;
        padding: 20px;
      }
          
      /* Media Query for low resolution  Tablets, Ipads */
      @media (min-width: 481px) and (max-width: 768px) {
        align-items: center;
        text-align: center;
        margin-right: 0;
        margin-bottom: 60px;
        padding: 20px;
      }
   


    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;

      .jippiLogo {
        margin-left: 40px;
        width: 180px;
        height: 90px;
        background-image: url(../../../images/jippi_black.png);
        background-repeat: round;
        display: none;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          width: 120px;
          height: 60px;
        }
      
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 768px) {
          width: 120px;
          height: 60px;
        }
      }


      .logo {
        border-radius: 16px;
        width: 96px;
        margin-bottom: 0;
        box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        width: 96px;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        width: 96px;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 64px;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 64px;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        width: 64px;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        width: 64px;
      }

        &.hidden {
          opacity: 0;
          filter: blur(5px);
          transform: translateY(100%);
          transition: all 1s;
        }

        &.show {
          opacity: 1;
          filter: blur(0);
          transform: translateY(0);
        }
      }
    }

    .rowAlt {
      display: flex;
      flex-direction: 'row';
      padding-top: 32px;

      .badge {
        cursor: pointer;
        width: 148px;
        transition: transform 200ms ease;
        box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
        border-radius: 8px;

        &:hover {
          transform: translate(0px, -2px);
          transition-timing-function: ease-in;
          transition: 0.5s;
        }

        &:first-child {
          margin-right: 12px;
        }
      }
    }

    .title {
      margin-top: 40px;
      margin-bottom: 0px;
      font-weight: bold;
      color: #171717;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        font-size: 48px;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        font-size: 48px;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 32px;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 32px;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        font-size: 32px;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        font-size: 32px;
      }
    }

    .subtitle {
      margin-top: 20px;
      margin-bottom: 0px;
      color: #171717;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        font-size: 24px;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        font-size: 24px;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 20px;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 20px;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        font-size: 20px;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }

  .container {
    position: relative;
    width: 300px;

     /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        height: 560px;
        margin-left: 200px;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        height: 460px;
        margin-left: 100px;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        height: 360px;
        margin-left: 0;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        height: 360px;
        margin-left: 0;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        height: 360px;
  
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        height: 360px;
      }

    .iPhonePhrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  
    .iPhoneVideo {
      top: 2%;
      left: 0;
      border-radius: 16%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 96%;
    }
  }
}
