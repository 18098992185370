.cover {
  font-family: "Nunito";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  text-align: center;

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 32px;
    font-weight: bold;
  }

  .subtitle {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #171717;
    font-size: 20px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 60px;

  /* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 767px) {
      flex-direction: column;
    }

    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;

    }
    

    .container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      text-align: center;
      padding-bottom: 40px;
      padding-left: 60px;
      padding-right: 60px;

      .image {
        height: 200px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        margin-bottom: 40px;
      }

      .capitals {
        color: #13B6B8;
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
        letter-spacing: 4px;
        font-weight: bold;
      }

      .title {
        margin-top: 16px;
        margin-bottom: 0px;
        color: #171717;
        font-size: 32px;
        font-weight: bold;
      }

      .subtitle {
        margin-top: 16px;
        margin-bottom: 16px;
        color: #171717;
        font-size: 20px;
      }
    } 
  }
}
