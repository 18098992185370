.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;
  align-items: center;
  text-align: center;

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    color: #171717;
    font-size: 32px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 0;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      padding-left: 44px;
      padding-right: 44px;

    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      padding-left: 44px;
      padding-right: 44px;
    }
  }

  .gridContainer {
    margin-top: 42px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;
    width: 63em;
    
    /* 
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 42em;
    }

    /* 
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 42em;
    }

    /* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 767px) {
      width: 100%;
      padding-left: 100px;
      padding-right: 100px;
    }

    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {
      width: auto;
      display: block;
      margin: 20px;
    }
  }

  .gridItem {
    background-image: linear-gradient(90deg, rgba(19, 182, 184, 1) 0%, rgba(73, 208, 190, 1) 100%);
    color: white;
    padding: 40px;
    border-radius: 40px;
    text-align: left;
    box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {
      margin-bottom: 20px;
    }

    &:first-of-type {
      grid-column: span 2;
      background-image: url(../../images/piggy_bank.jpg);
      background-size: cover;
      text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75), 0 0 2px rgba(21, 19, 17, 1);
      box-shadow: inset 0 0 0 1000px rgba(0,0,0,.1);

      &.gridItem button {
        background: linear-gradient(180deg, #ffffff 0%, #a3a3a3 100%);
        box-shadow: 0px 4px 10px 4px rgba(255, 255, 255, 0.2);
        color: black;
      }
    }

    &:nth-of-type(3) {
      background-image: url(../../images/beasts.png);
      background-size: cover;
      text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75), 0 0 2px rgba(21, 19, 17, 1);
      background-position: center top;
      box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);

      &.gridItem button {
        background: linear-gradient(180deg, #ffffff 0%, #a3a3a3 100%);
        box-shadow: 0px 4px 10px 4px rgba(255, 255, 255, 0.2);
        color: black;
      }
    }

    &:nth-of-type(4) {
      background-image: url(../../images/trading_card.png);
      background-size: cover;
      text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75), 0 0 2px rgba(21, 19, 17, 1);
      background-position: center top;
      box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);

      &.gridItem button {
        background: linear-gradient(180deg, #ffffff 0%, #a3a3a3 100%);
        box-shadow: 0px 4px 10px 4px rgba(255, 255, 255, 0.2);
        color: black;
      }
    }

    &:nth-of-type(5) {
  

      &.gridItem .link {
        background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
        box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
        color: white;
      }
    }
  }

  .gridItem h3 {
    font-size: 26px;
    margin: 0;
  }

  .gridItem p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .gridItem button {
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100px;
    background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
    box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
    color: white;
    font-family: "Nunito";
    margin: 0;
    transition: transform 200ms ease;
    font-weight: 600;
  }

  .gridItem .link {
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100px;
    background: linear-gradient(180deg, #ffffff 0%, #a3a3a3 100%);
    box-shadow: 0px 4px 10px 4px rgba(255, 255, 255, 0.2);
    color: black;
    font-family: "Nunito";
    margin: 0;
    transition: transform 200ms ease;
    font-weight: 600;
    text-shadow: none;
    text-decoration: none;
  }

  .gridItem button:hover {
    transform: translate(0px, -2px);
  }


  .gridItem .link:hover {
    transform: translate(0px, -2px);
  }

  @media (max-width: 768px) {
    .gridContainer {
      grid-template-columns: 1fr; /* Stacks items on smaller screens */
    }

    .gridItemLarge {
      grid-column: span 1; /* No spanning on small screens */
    }
  }
}